export default {
  NEAREST_RESERVATION: '/',
  PRE_RESERVE: '/pre-reserve',
  MAIL_SUCCESS: '/mail-success',
  BAPTIZED: '/baptized',
  PARENTS: '/parents',
  GODPARENTS: '/godparents',
  CONFIRM_RESERVATION: '/confirm-reservation',
  RESERVATION_DONE: '/reservation-done',
  REQUEST_RESERVATIONS: '/request-reservations',
  MY_RESERVATIONS: '/my-reservations',
  DATE_SELECTION: '/date-selection',
  CONFIRM_DATA: '/confirm-data',
  DOCUMENTS: '/documents',
  EXPRESS: '/date-selection/express'
};
