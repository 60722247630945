import { FC } from 'react';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import media from '../../../constants/media';
import { FooterProps } from './types';

const BaseFooter: FC<FooterProps> = ({ className }) => (
  <footer className={className}>
    Pastoral Digital. Todos los derechos reservados.
  </footer>
);

const Footer = styled(BaseFooter)`
  box-sizing: border-box;
  color: ${colors.darkerBlue};
  font-size: 0.625rem;
  margin-top: auto;
  padding: 1.5em;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @media (min-width: ${media.md}px) {
    font-size: 0.875rem;
    padding: 2em;
  }
`;

export default Footer;
