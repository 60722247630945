import styled from 'styled-components';
import colors from '../../../constants/colors';
import media from '../../../constants/media';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const MainContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  form {
    width: 100%;
  }
  @media (min-width: ${media.md}px) {
    padding: 0px 135px;
  }
`;

export const StepTitle = styled.h2`
  color: ${colors.darkBlue};
  font-size: 1.125rem;
  font-weight: 900;
  margin: 10px 0 0 0;
  text-align: center;
`;
