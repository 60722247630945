import i18next from 'i18next';

i18next.addResources('es', 'Parents', {
  title: 'Solicitar o consultar turno',
  subTitle: 'Complete los campos con sus datos personales',
  fatherTitle: 'Datos del padre',
  motherTitle: 'Datos de la madre',
  tutorTitle: 'Datos del tutor o encargado',
  lastName: 'Apellidos *',
  name: 'Nombres *',
  phone: 'Teléfono',
  email: '',
  document: 'Nº de documento',
  next: 'Siguiente',
  stepTitle: 'Paso 1/3',
});
