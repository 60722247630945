import * as ReactDOM from 'react-dom';
import App from './app/App';
import { GlobalStateProvider } from './config/store';
import initialState from './constants/initialState';

ReactDOM.render(
  <GlobalStateProvider initialValues={initialState}>
    <App />
  </GlobalStateProvider>,
  document.getElementById('app'),
);
