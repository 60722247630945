export default {
  blue: '#2F80ED',
  darkBlue: '#002E5A',
  darkerBlue: '#03509A',
  white: '#FFFFFF',
  border: '#E5E5E5;',
  background: '#FAFAFA',
  inputBorder: '#DADADA',
  placeHolder: '#7A7A7A',
  dayColor: '#4F4F4F',
  grey: '#CCC',
  red: '#EE0202',
  data: '#3C3844',
};
