import i18next from 'i18next';

i18next.addResources('es', 'MyReservations', {
  title: 'Reservas realizadas por',
  reservationTitle: 'Bautismo de {{name}}',
  date: 'Fecha: {{date}}',
  hour: 'Hora: {{time}}',
  godfather: 'Padrino: {{name}}',
  godmother: 'Madrina: {{name}}',
});
