import i18next from 'i18next';

i18next.addResources('es', 'ReservationDone', {
  title: 'Solicitud completada',
  success: 'El turno ha sido reservado',
  accepted: 'Se te enviará toda la información por email.',
  donations: 'Donaciones',
  finish: 'Finalizar',
  titleDonations: 'Te solicitamos una donación',
  petition:
    'Para poder llevar adelante los bautismos en condiciones sanitarias seguras considerando COVID, desde el santuario hemos tenido que hacer grandes esfuerzos no solo en la aplicación que estás utilizando en este momento sino también en tecnología y equipamiento. Por todo esto te pedimos que POR FAVOR nos ayudes con una contribución solidaria para que juntos podamos realizar muchos bautismos más.',
  donateNow: 'Donar ahora',
  donateLater: 'Donar en el santuario',
});
