import i18next from 'i18next';

i18next.addResources('es', 'Errors', {
  required: 'Este campo es requerido',
  connectionErrorTitle: 'Error de conexión',
  connectionErrorDescription:
    'Estamos teniendo dificultades con el servidor. Espere unos minutos e intente nuevamente.',
  connectionErrorLabel: 'Aceptar',
  invalidEmail: 'Mail inválido',
  badRequestExpiredErrorTitle: 'Error de solicitud',
  badRequestExpiredErrorDescription: 'La solicitud de bautismo por este medio ya fue realizada.',
  badRequestExpiredErrorLabel: 'Aceptar',
});
