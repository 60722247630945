import i18next from 'i18next';

i18next.addResources('es', 'Godparents', {
  title: 'Solicitar o consultar turno',
  subTitle: 'Complete los campos con sus datos personales',
  baptizedTitle: 'Seleccionar bautizado',
  godFatherTitle: 'Datos del padrino',
  godMotherTitle: 'Datos de la madrina',
  lastName: 'Apellidos *',
  name: 'Nombres *',
  document: 'Nº de documento',
  next: 'Siguiente',
  back: 'Volver',
  select: 'Seleccionar',
  stepTitle: 'Paso 3/3',
});
