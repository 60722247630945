import { FC, lazy, Suspense } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../config/api';
import routes from '../constants/routes';
import ConnectionError from './components/ConnectionError/ConnectionError';
import BadRequestError from './components/BadRequestError/BadRequestError';
import { MainHeader } from './components/Header/Header';
import { MainContent, Wrapper } from './components/Layout/Layout';
import Footer from './components/Footer/Footer';
import Loader from './components/Loader/Loader';

const GlobalStyles = createGlobalStyle`
  html, body {
    background-color: #F5F5F5;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
`;

const GlobalLoaderContainer = styled.div`
  height: calc(100% - 72px);
  position: absolute;
  top: 0;
  width: 100%;
`;

const LazyNearestReservation = lazy(
  () => import('./pages/NearestReservation/NearestReservation'),
);
const LazyPreReserve = lazy(() => import('./pages/PreReserve/PreReserve'));
const LazyMailSuccess = lazy(() => import('./pages/MailSuccess/MailSuccess'));
const LazyBaptized = lazy(() => import('./pages/Baptized/Baptized'));
const LazyParents = lazy(() => import('./pages/Parents/Parents'));
const LazyGodparents = lazy(() => import('./pages/Godparents/Godparents'));
const LazyReservationDone = lazy(
  () => import('./pages/ReservationDone/ReservationDone'),
);
const LazyRequestReservations = lazy(
  () => import('./pages/RequestReservations/RequestReservations'),
);
const LazyMyReservations = lazy(
  () => import('./pages/MyReservations/MyReservations'),
);
const LazyDateSelection = lazy(
  () => import('./pages/DateSelection/DateSelection'),
);
const LazyConfirmData = lazy(() => import('./pages/ConfirmData/CofirmData'));
const LazyDocuments = lazy(() => import('./pages/Documents/Documents'));

const App: FC = () => (
  <Router>
    <GlobalStyles />
    <ConnectionError />
    <BadRequestError />
    <Wrapper>
      <MainHeader />
      <MainContent>
        <Suspense
          fallback={
            <GlobalLoaderContainer>
              <Loader />
            </GlobalLoaderContainer>
          }
        >
          <Switch>
            <Route exact path={routes.NEAREST_RESERVATION}>
              <LazyNearestReservation />
            </Route>
            <Route exact path={routes.PRE_RESERVE}>
              <LazyPreReserve />
            </Route>
            <Route exact path={routes.MAIL_SUCCESS}>
              <LazyMailSuccess />
            </Route>
            <Route exact path={routes.BAPTIZED}>
              <LazyBaptized />
            </Route>
            <Route exact path={routes.PARENTS}>
              <LazyParents />
            </Route>
            <Route exact path={routes.GODPARENTS}>
              <LazyGodparents />
            </Route>
            <Route exact path={routes.RESERVATION_DONE}>
              <LazyReservationDone />
            </Route>
            <Route exact path={routes.REQUEST_RESERVATIONS}>
              <LazyRequestReservations />
            </Route>
            <Route exact path={routes.MY_RESERVATIONS}>
              <LazyMyReservations />
            </Route>
            <Route exact path={routes.DATE_SELECTION}>
              <LazyDateSelection />
            </Route>
            <Route exact path={routes.CONFIRM_DATA}>
              <LazyConfirmData />
            </Route>
            <Route exact path={routes.DOCUMENTS}>
              <LazyDocuments />
            </Route>
            <Route exact path={routes.EXPRESS}>
              <LazyDateSelection/>
            </Route>
          </Switch>
        </Suspense>
      </MainContent>
      <Footer />
    </Wrapper>
  </Router>
);

export default App;
