import { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import pLogo from '../../assets/plogo.png';
import dLogo from '../../assets/dlogo.png';
import backIcon from '../../assets/back.svg';
import { HeaderProps } from './types';
import {
  OnlyMobile,
  OnlyDesktop,
} from '../MediaQueryDisplay/MediaQueryDisplay';
import media from '../../../constants/media';

const Header: FC<HeaderProps> = ({ className }) => {
  const history = useHistory();
  return (
    <header className={className}>
      <OnlyMobile>
        <img
          className="back"
          role="presentation"
          onClick={history.goBack}
          src={backIcon}
          alt="back"
        />
        <div className="title">
          <h1>
            Solicitud de turnos <br /> para bautismo
          </h1>
        </div>
        <img src={pLogo} alt="" />
      </OnlyMobile>
      <OnlyDesktop>
        <img src={dLogo} alt="" />
        <div className="title">
          <h1>Solicitud de turnos para bautismo</h1>
        </div>
      </OnlyDesktop>
    </header>
  );
};

export const MainHeader = styled(Header)`
  align-items: center;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  display: flex;
  justify-content: flex-end;
  .back {
    margin-right: auto;
  }
  h1 {
    font-size: 1rem;
    margin: 0px;
  }
  .title {
    border-right: ${colors.white} solid 2px;
    margin: 10px 0;
    padding: 0px 15px 0px 0px;
    text-align: right;
    white-space: pre;
  }
  img {
    margin: 0px 10px;
  }
  @media (min-width: ${media.md}px) {
    justify-content: flex-start;
    min-height: 56px;
    .title {
      border-left: ${colors.white} solid 2px;
      border-right: none;
      margin: 16px 0px;
      padding: 2px 0px 2px 30px;
    }
    img {
      height: 40px;
      margin: 0px 27px 0px 10px;
    }
  }
`;
