import { FC, useCallback, useEffect, useState } from 'react';
import { useI18next } from '../../../config/i18next';
import { ErrorModal } from '../Modal/Modal';

export const showError = (data: any) => {
  const errorEvent = new CustomEvent('bad-request-error', { detail: data });
  document.dispatchEvent(errorEvent);
};

const BadRequestError: FC = () => {
  const [t] = useI18next();
  const [error, setError] = useState(null);
  const onClose = useCallback(() => {
    setError(null);
  }, []);
  useEffect(() => {
    document.addEventListener('bad-request-error', (event: CustomEvent) => {
      setError(event.detail);
    });
  }, []);

  return (
    <ErrorModal
      title={t('Errors:badRequestExpiredErrorTitle')}
      description={t('Errors:badRequestExpiredErrorDescription')}
      label={t('Errors:badRequestExpiredErrorLabel')}
      isOpen={error}
      onClose={onClose}
    />
  );
};

export default BadRequestError;
