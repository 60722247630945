import { FC } from 'react';
import media from '../../../constants/media';

export const OnlyMobile: FC = ({ children }) => {
  if (window.innerWidth >= media.md) {
    return null;
  }
  return <>{children}</>;
};

export const OnlyDesktop: FC = ({ children }) => {
  if (window.innerWidth < media.md) {
    return null;
  }
  return <>{children}</>;
};
