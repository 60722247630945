const initialState = {
  baptized: {
    lastName: '',
    name: '',
    pid: '',
    birthDate: '',
    place: {
      name: '',
      country: {
        id: null as number,
      },
      province: {
        id: null as number,
      },
      locality: {
        id: null as number,
      },
    },
    gender: '',
  },
  dateTime: null as Date,
  parents: {
    father: {
      lastName: '',
      name: '',
      pid: '',
      email: '',
      phoneNumber: '',
      doesNotHave: false,
    },
    mother: {
      lastName: '',
      name: '',
      pid: '',
      email: '',
      phoneNumber: '',
      doesNotHave: false,
    },
    tutor: {
      lastName: '',
      name: '',
      pid: '',
      email: '',
      phoneNumber: '',
    },
  },
  godparents: {
    godfather: {
      name: '',
      lastName: '',
    },
    godmother: {
      name: '',
      lastName: '',
    },
  },
  registration: {
    dateReservation: '',
    expirationDate: '',
    uuid: '',
    status: '',
    email: '',
    uuidBaptism: '',
  },
};

export default initialState;
