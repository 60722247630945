import i18next from 'i18next';

i18next.addResources('es', 'ConfirmData', {
  title: 'Su turno reservado',
  subTitle: 'Datos ingresados',
  success: 'Ha seleccionado con éxito',
  date: 'Para la fecha:',
  hour: 'En el horario:',
  finish: 'Siguiente',
  name: 'Nombre: {{name}}',
  pid: 'DNI: {{pid}}',
  day: 'Día: {{day}}',
  time: 'Hora: {{time}}',
  phone: 'Teléfono: {{phone}}',
  mother: 'Madre: {{name}}',
  father: 'Padre: {{name}}',
  godfather: 'Padrino: {{name}}',
  godmother: 'Madrina: {{name}}',
  stepTitle: '¡Ya te falta poco para completar el proceso!',
});
