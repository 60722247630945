import i18next from 'i18next';

i18next.addResources('es', 'Baptized', {
  title: 'Solicitar o consultar turno',
  subTitle: 'Complete los campos con sus datos personales',
  baptizedTitle: 'Datos del bautizado',
  lastName: 'Apellidos *',
  name: 'Nombres *',
  document: 'Nº de documento',
  birthDate: 'Fecha de nacimiento *',
  country: 'País *',
  province: 'Provincia',
  locality: 'Localidad',
  addMore: '+ Agregar más',
  gender: 'Género *',
  back: 'Volver',
  next: 'Siguiente',
  stepTitle: 'Paso 2/3',
});
