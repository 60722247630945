import i18next from 'i18next';

i18next.addResources('es', 'RequestReservations', {
  title: 'Mis Reservas',
  mailTitle: 'Ingrese su mail',
  emailPlaceholder: 'Email',
  send: 'Acceder a mis reservas',
  description:
    'En breve te llegará un mail y podrás acceder a tus reservas desde ahi',
  accept: 'Aceptar',
});
